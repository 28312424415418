










































import { Component } from 'vue-property-decorator'
import myMixins from '@/mixins/common.ts'
import API from '@/api/index.js'
import Util from '@/utils/index.js'
import getCodeByAppId from '@/getCodeByAppId.js'

@Component({
    components: {}
})
export default class PersonlInfo extends myMixins {
    userInfo = [
        {
            label: '昵称',
            value: '',
            prop: 'nick_name'
        },
        {
            label: '手机号',
            value: '',
            prop: 'mobile'
        },
        {
            label: '邮箱',
            value: '',
            prop: 'mail'
        },
        {
            label: '企业',
            value: '',
            prop: 'company'
        },
        {
            label: '密码',
            value: '',
            prop: 'password',
            rText: '修改'
        }
    ]
    show = false
    showWithCancel = false
    dialogTitle = '' // 弹窗名字
    placeholder = '' // 弹窗输入文字提示
    value = '' // 弹窗输入值
    changeProp = '' // 弹窗修改baseInfo 属性
    baseInfo: any = {}
    unBindText = '解绑微信后你将无法使用微信登录，你确定要解绑吗？'
    bindText = '绑定当前使用微信'
    bindStatus = false // 是否已经绑定了union_id  true: 已绑定  false: 未绑定

    mounted() {
        if (this.$store.state.login.isWxBrower) {
            this.userInfo.splice(4, 0, {
                label: '微信',
                value: '',
                prop: 'union_id',
                rText: '未绑定'
            })
        }
        this.sureBind()
        this.getBaseInfo()
    }

    async getBaseInfo() {
        const res = await API.getBaseInfo()
        this.baseInfo = res.data.data
        for (const prop in this.baseInfo) {
            this.userInfo.forEach((item: any) => {
                if (item.prop === prop) {
                    item.value = this.baseInfo[prop]
                    if (prop === 'union_id') {
                        if (this.baseInfo[prop] !== '') {
                            item.rText = '解绑'
                            this.bindStatus = true
                        } else {
                            item.rText = '未绑定'
                            this.bindStatus = false
                        }
                    }
                }
            })
        }
        this.$store.commit('login/updateUserInfo', res)
    }

    itemCli(params: Param) {
        const { rText, label, value, prop } = params
        if (rText) {
            if (rText === '解绑' || rText === '未绑定') {
                this.showWithCancel = true
            } else if (rText === '修改') {
                this.routerChange('changePassword')
            }
        } else {
            if (label === '手机号') {
                this.routerChange('lastPhone')
            } else {
                this.dialogTitle = `修改${label}`
                this.show = true
                this.value = value
                this.placeholder = `请输入${label}`
                this.changeProp = prop
            }
        }
    }

    async confirm() {
        if (this.value !== '') {
            this.baseInfo[this.changeProp] = this.value
            await API.changeBaseInfo(this.baseInfo)
            this.$toast('保存成功')
            this.getBaseInfo()
        }
    }

    async wechatBind() {
        if (this.bindStatus) {
            // 解绑
            await API.unBindWechat()
            this.getBaseInfo()
        } else {
            // 绑定
            this.$store.commit('login/updateIsPersonalBind', true)
            this.$nextTick(() => {
                const { appId } = Util.parseUrl(window.location.href) as any
                getCodeByAppId(appId)
            })
        }
    }

    async sureBind() {
        console.log('personal sure bind wechat unionid: ', this.$store.state.login.isPersonalBind)
        // 如果是该页面的个人绑定信息发起 isPersonalBind： true  则用获取的code来绑定微信
        if (this.$store.state.login.isPersonalBind) {
            const { code, appName } = Util.parseUrl(window.location.href) as any
            if (code && appName) {
                await API.bindWechat({ code, appName })
                this.$store.commit('login/updateIsPersonalBind', false)
                this.getBaseInfo()
            }
        }
    }
}
