//定义要混合的类 mixins.ts
import Vue from 'vue'
import Component from 'vue-class-component'
import Util from '@/utils/index.js'

@Component // 一定要用Component修饰
export default class MyMixins extends Vue {
    timer: any = null
    iosBlannkTimer: any = null

    // 获取验证码
    getYZM() {
        this.timer = this.$store.state.login.timer
        if (!this.timer) {
            const { timeNumber } = this.$store.state.login
            this.$store.commit('login/updateTimeNumber', timeNumber < 0 ? 60 : timeNumber)
            this.timer = setInterval(() => {
                const { timeNumber } = this.$store.state.login
                if (timeNumber > 0) {
                    const num = this.$store.state.login.timeNumber - 1
                    this.$store.commit('login/updateTimeNumber', num)
                } else {
                    clearInterval(this.timer)
                    this.timer = null
                    this.$store.commit('login/updateTimeNumber', -1)
                    this.$store.commit('login/updateTimer', this.timer)
                }
            }, 1000)
            this.$store.commit('login/updateTimer', this.timer)
        }
    }

    clearTimer() {
        clearInterval(this.timer)
        this.timer = null
        this.$store.commit('login/updateTimer', this.timer)
    }

    checkUserInfo(ctx: any, isReg = false) {
        let status = true
        const { phone, password } = ctx.userInfo
        if (!Util.checkPhone(phone)) {
            status = false
        }
        if (password === '') {
            status = false
        }
        if (isReg && ctx.vertificationCode.length === 6) {
            ctx.userInfo.vertificationCode = ctx.vertificationCode
        }
        if (isReg && ctx.vertificationCode.length !== 6) {
            status = false
        }

        return status
    }

    routerChange(name: string) {
        this.$router.push({
            name
        })
    }

    login(res: any) {
        if (res && res.data && res.data.errorCode === 200) {
            const { accessToken } = res.data.data
            const { redirectUrl } = this.$store.state.login
            this.$store.commit('login/updateAccessToken', accessToken)
            this.$store.commit('login/updateUserInfo', res.data.data)
            this.$store.commit('login/updateInputPhone', '')
            localStorage.setItem('yt_h5_accesstoken', accessToken)

            this.$nextTick(() => {
                if (redirectUrl) {
                    if (redirectUrl.includes('?')) {
                        window.location.href = `${decodeURIComponent(redirectUrl as string)}&token=${accessToken}`
                    } else {
                        window.location.href = `${decodeURIComponent(redirectUrl as string)}?token=${accessToken}`
                    }
                } else {
                    // 进入官网首页 对应routeName
                    this.$router.push({
                        name: 'home'
                    })
                }
            })
        }
    }

    reOautho() {
        window.location.reload()
    }

    inputFocus() {
        clearTimeout(this.iosBlannkTimer)
    }

    inputFocusout() {
        this.iosBlannkTimer = setTimeout(() => {
            window.scrollTo(0, 0)
            // 间隔设为10，减少页面失去焦点定时器的突兀感，
        }, 10)
    }
}
